import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    lng: "en",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: {
            translation: {
                general_cancel: "Cancel",
                general_delete: "Delete",
                general_save: "Save",
                general_back: "Back",
                general_okay: "Okay",
                general_loading: "Loading",
                general_close: "Close",
                general_add: "Add",

                login: "Sign in",
                login_wrong_credentials: "Wrong username or password",

                navbar_hello: "Hi",

                deliveries_title: "Deliveries",
                deliveries_bale_feed_title: "Bale Feed",
                deliveries_loading: "loading",
                deliveries_new: "New Delivery",

                delivery: "Delivery",
                delivery_images_delivery: "Delivery Images",
                delivery_images_bales: "Bale Images",
                delivery_bales_detected: "Bales detected",
                delivery_confirm_delete: "Are you sure you want to delete this delivery?",
                delivery_import: "Update Delivery",
                delivery_import_success: "Bales successfully imported into delivery!",
                delivery_confirm_delete_image: "Are you sure you want to delete this image?",
                delivery_confirm_delete_image_info: "All associated bales will be removed as well.",
                delivery_bale_detection_no_bales_detected: "No bales were detected.",
                delivery_bale_detection_error: "An error occurred during bale detection.",

                reclamation: "Reclamation",
                reclamation_photos:"Reclamation Photos",
                reclamation_linked_bales: "Linked Bales",
                reclamation_confirm: "Confirm & Submit",
                reclamation_send_confirm_title: "Do you want to notify about the reclamation?",
                reclamation_send_warning: "Notification already sent!",
                reclamation_send: "Notify",
                reclamation_send_confirmation: "Notification successfully sent!",
                reclamation_not_sent_confirmation: "Reclamation saved. No emails were sent.",
                reclamation_discrepancy_delivery_note: "Discrepancy Delivery Note",
                reclamation_wetness: "Wetness too high",
                reclamation_inadmissible_material: "Inadmissible material",
                reclamation_unwanted_material: "Unwanted material",
                reclamation_not_all_bales_marked: "Not all bales marked",

                weighing_slip_extraction_success: "Information successfully extracted.",
                weighing_slip_extraction_running: "Extracting information...",
                weighing_slip_extraction_error: "Extraction failed. Try again.",
                weighing_slip_extraction_info: "Take a picture of the weighing slip to automatically extract information.",
                weighing_slip_take_picture: "Take Picture",

                instructions_import_bales: "Instructions: Import Bales",
                instructions_scan_truck: "Instructions: Scan Truck",

                labeling_panel_toggle: "Labeling Panel",
                labeling_title: "Labeling",

                property_field_extendable_new: "New entry?",
                property_notes: "NOTES",
                property_supplier: "SUPPLIER",
                property_storage_field: "STORAGE FIELD",
                property_date: "DATE",
                property_submitted_by: "SUBMITTED BY",
                property_problems: "PROBLEM POINTS",
                property_material: "MATERIAL",
                property_material_group: "MATERIAL GROUP",
                property_weighing_slip: "WEIGHING SLIP NO",
                property_supplier_no: "SUPPLIER NO",
                property_supplier_name: "SUPPLIER NAME",
                property_order_no: "ORDER NO",
                property_license_plate: "LICENSE PLATE TRUCK",
                property_weight_first: "1. WEIGHING",
                property_weight_second: "2. WEIGHING",
                property_weight_netto_1: "NETTO I",
                property_weight_netto_2: "NETTO II",

                // Shared Components
                labeling_select_recommended: "Recommended",
                labeling_select_others: "Others",
                labeling_request: "Labeling request",
                labeling_skip: "Skip label",
                labeling_no_more_requests: "No more labeling requests found.",
                license_agreement_title: "License Agreement",
                license_agreement_decline: "Decline",
                license_agreement_accept: "Accept",
            }
        },
        de: {
            translation: {
                general_cancel: "Abbrechen",
                general_delete: "Löschen",
                general_save: "Speichern",
                general_back: "Zurück",
                general_okay: "Okay",
                general_loading: "Loading",
                general_close: "Schliessen",
                general_add: "Hinzufügen",

                login: "Anmelden",
                login_wrong_credentials: "Falscher Benutzername oder Passwort.",

                navbar_hello: "Hi",

                deliveries_title: "Lieferungen",
                deliveries_bale_feed_title: "Förderband Beschickung",
                deliveries_loading: "laden",
                deliveries_new: "Neue Lieferung",

                delivery: "Lieferung",
                delivery_images_delivery: "Fotos Lieferung",
                delivery_images_bales: "Fotos Ballen",
                delivery_bales_detected: "Ballen detektiert",
                delivery_confirm_delete: "Bist Du sicher, dass Du die Lieferung löschen möchtest?",
                delivery_import: "Lieferung aktualisieren",
                delivery_import_success: "Ballen erfolgreich in Lieferung importiert!",
                delivery_confirm_delete_image: "Bist Du sicher, dass Du dieses Foto löschen möchtest?",
                delivery_confirm_delete_image_info: "Alle assoziierten Ballen werden ebenfalls dabei entfernt.",
                delivery_bale_detection_no_bales_detected: "Es wurden keine Ballen detektiert.",
                delivery_bale_detection_error: "Ein Fehler ist während der Ballen Detektion aufgetreten.",

                reclamation: "Reklamation",
                reclamation_photos:"Fotos Reklamation",
                reclamation_linked_bales: "Verlinkte Ballen",
                reclamation_confirm: "Bestätigen & Einreichen",
                reclamation_send_confirm_title: "Möchtest Du über die Reklamation benachrichtigen?",
                reclamation_send_warning: "Benachrichtigung wurde bereits gesendet!",
                reclamation_send: "Benachrichtigen",
                reclamation_send_confirmation: "Benachrichtigung erfolgreich gesendet!",
                reclamation_not_sent_confirmation: "Reklamation gespeichert. Es wurden keine Emails versendet.",
                reclamation_discrepancy_delivery_note: "Unstimmigkeit Ladegut",
                reclamation_wetness: "Feuchtegehalt zu hoch",
                reclamation_inadmissible_material: "Unzulässiges Material",
                reclamation_unwanted_material: "Ungewolltes Material",
                reclamation_not_all_bales_marked: "Ballen nicht gekennzeichnet",

                weighing_slip_extraction_success: "Informationen erfolgreich extrahiert.",
                weighing_slip_extraction_running: "Extrahiere Informationen...",
                weighing_slip_extraction_error: "Extrahieren fehlgeschlagen. Versuche es erneut.",
                weighing_slip_extraction_info: "Nimm ein Foto des Wiegescheins um automatisch Informationen zu extrahieren.",
                weighing_slip_take_picture: "Mach ein Foto",

                instructions_import_bales: "Anleitung: Ballen Importieren",
                instructions_scan_truck: "Anleitung: Truck Scannen",

                labeling_panel_toggle: "Labeling Panel",
                labeling_title: "Labeling",

                property_field_extendable_new: "Neuen Eintrag erfassen?",
                property_notes: "NOTIZEN",
                property_supplier: "LIEFERANT",
                property_storage_field: "LAGER FELD",
                property_date: "DATUM",
                property_submitted_by: "ERFASSER",
                property_problems: "PROBLEMPUNKTE",
                property_material: "MATERIAL",
                property_material_group: "MATERIAL GRUPPE",
                property_weighing_slip: "WIEGESCHEIN NR",
                property_supplier_no: "LIEFERANTEN NR",
                property_supplier_name: "LIEFERANTEN NAME",
                property_order_no: "BESTELL NR",
                property_license_plate: "KENNZEICHEN LKW",
                property_weight_first: "1. WÄGUNG",
                property_weight_second: "2. WÄGUNG",
                property_weight_netto_1: "NETTO I",
                property_weight_netto_2: "NETTO II",

                // Shared Components
                labeling_select_recommended: "Empfohlen",
                labeling_select_others: "Andere",
                labeling_request: "Labeling Anfrage",
                labeling_skip: "Unbestimmt lassen",
                labeling_no_more_requests: "Keine weiteren labeling Anfragen gefunden.",
                license_agreement_title: "Lizenzvereinbarung",
                license_agreement_decline: "Ablehnen",
                license_agreement_accept: "Akzeptieren",
            }
        },
    },
});

export default i18n;